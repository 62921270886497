.container {
  display: flex;
  justify-content: center;

  .image-circle {
    height: 200px;
    border-radius: 200px;
    border: 4px solid #cacaca;
  }
}
