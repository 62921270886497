/* Sets the value of a css var, $base or $_prop, 
  to be the value of a size variant of that css var, with fallbacks to smaller sizes
*/
@mixin fallback-sizes($base, $size, $_prop: none) {
  $prop: $base;
  @if $_prop != none {
    $prop: $_prop;
  }
  @if $size == sm {
    #{$prop}: var(#{$base}-sm, var(#{$base}-xs, initial));
  } @else if $size == md {
    #{$prop}: var(#{$base}-md, var(#{$base}-sm, var(#{$base}-xs, initial)));
  } @else if $size == lg {
    #{$prop}: var(#{$base}-lg, var(#{$base}-md, var(#{$base}-sm, var(#{$base}-xs, initial))));
  }
}
