.sidebar__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  //margin: 1em 0;
  font-size: 0.75em;
  padding: 15px 20px;
  color: white;
  text-decoration: none;

  &:hover,
  &.active {
    color: var(--clr-txt-white);
  }

  &:hover {
    cursor: pointer;
    background-color: rgba(128, 128, 128, 0.288);
  }

  &.active {
    background-color: rgba(190, 132, 94, 0.288);
  }

  &__left {
    display: flex;
    align-items: center;

    .icon {
      font-size: 1.4em;
      width: 21px;
    }

    .name {
      position: relative;
      top: 2px;
      margin-left: 10px;
    }
  }
}
