.list-item {
  &:not(:last-of-type) {
    margin-bottom: 10px;
  }

  .block {
    background-color: #cecece !important;
    transition: all 0.4s ease;

    &:hover {
      background-color: white !important;
      cursor: pointer;
    }
  }

  &.active {
    .block {
      background-color: white !important;
      box-shadow: 0 0 0 2px var(--clr-orange);
    }
  }
}
