:root {
  --topbar-height: 70px;
}

.topbar {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;

  background-color: var(--clr-purple-200);

  &__container {
    display: flex;
    justify-content: space-between;
    height: var(--topbar-height);
  }

  &__left,
  &__right {
    display: flex;
    align-items: center;
  }

  &__right {
    padding-right: 25px;
  }

  //&__right {
  //  justify-items: right;
  //}
}
