/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-clr);
}

*::-webkit-scrollbar {
  width: 15px;
  background-color: #131620;
}
*::-webkit-scrollbar-thumb {
  background-color: rgb(122, 122, 122);
}

:root {
  --clr-black: #303030;

  --clr-purple-100: #222635;
  --clr-purple-200: #1e2230;
  --clr-purple-900: #1b1f2b;
  --clr-lightblue-100: rgb(160, 180, 204);

  --clr-orange: #da6808;
  --clr-white: rgb(226, 224, 220);
  --clr-gray: rgb(106, 113, 135);

  --clr-bg: var(--cl-purple-900);
  --clr-fg: var(--clr-purple-100);
  --clr-txt-primary: var(--clr-lightblue-100);
  --clr-txt-accent: var(--clr-pink-100);
  --clr-txt-white: var(--clr-white);
  --clr-txt-secondary: var(--clr-gray);
  --text-clr: var(--clr-black);
}

.txt-primary {
  color: var(--clr-txt-primary) !important;
}
.txt-accent {
  color: var(--clr-txt-accent) !important;
}
.txt-secondary {
  color: var(--clr-txt-secondary) !important;
}
