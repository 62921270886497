.status-block {
  .status {
    text-transform: capitalize;
  }

  .edit-btn {
    visibility: hidden;
  }

  &:hover .edit-btn {
    visibility: visible;
  }
}

.menu-item {
  text-transform: capitalize;
}
