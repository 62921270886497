.floating-block {
  display: flex;
  justify-content: space-between;
  background-color: white;
  border-radius: 5px;
  padding: 10px 20px;
  margin: var(--margin);
  height: 100%;

  & > div:first-child {
    flex-grow: 1;
  }

  .label {
    opacity: 0.9;
    font-weight: bold;
    margin-bottom: 10px;
  }

  a {
    color: inherit;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
      color: var(--clr-orange); //rgb(192, 79, 3);
    }
  }

  .icon {
    margin-left: 5px;
  }
}
