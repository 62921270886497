.page-left {
  display: flex;
  flex-direction: column;

  :first-child {
    flex: 0 1 auto;
  }

  .list {
    flex: 1 0 50vh;
    margin-top: 20px;
    overflow: auto;
    padding: 2px 5px 0 2px;
    height: 0; // allows scrolling while flex handles actual height;

    &::-webkit-scrollbar {
      width: 5px;
      border-radius: 100px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 100px;
    }
  }
}

.filter-option {
  display: flex;
  align-items: flex-end;
}
