.page {
  padding: 20px;
  height: calc(100vh - var(--topbar-height));
  background-color: #eee;
  overflow: auto;

  .title {
    margin-bottom: 20px;
    font-weight: bold;
  }
}
