.login-page {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('/static/img/lighthouse.jpg');
  background-repeat: no-repeat;
  background-size: 115%;
  background-position: 2% 78%;

  .login-card {
    padding: 50px;
    display: flex;
    align-items: center;
    flex-direction: column;

    .errMsg {
      color: red;
    }

    .login-btn {
      margin-top: 10px;
    }

    .or {
      margin-top: 16px;
      font-weight: bold;
      opacity: 0.5;
    }
  }
}
