@import '../_partial.scss';

.cell {
  --area-xs: initial;
  --area-sm: initial;
  --area-md: initial;
  --area-lg: initial;
  --area: var(--area-xs);

  --col-offset-xs: initial;
  --col-offset-sm: initial;
  --col-offset-md: initial;
  --col-offset-lg: initial;
  --col-offset: var(--col-offset-xs);

  --col-span-xs: initial;
  --col-span-sm: initial;
  --col-span-md: initial;
  --col-span-lg: initial;
  --col-span: var(--col-span-xs);

  --row-offset-xs: initial;
  --row-offset-sm: initial;
  --row-offset-md: initial;
  --row-offset-lg: initial;
  --row-offset: var(--row-offset-xs);

  --row-span-xs: initial;
  --row-span-sm: initial;
  --row-span-md: initial;
  --row-span-lg: initial;
  --row-span: var(--row-span-xs);
}

.cell {
  grid-area: var(--area, initial);
  grid-column-start: var(--col-offset);
  grid-column-end: var(--col-span);
  grid-row-start: var(--row-offset);
  grid-row-end: var(--row-span);

  @media (min-width: 480px) {
    @include fallback-sizes(--area, sm);
    @include fallback-sizes(--col-offset, sm);
    @include fallback-sizes(--col-span, sm);
    @include fallback-sizes(--row-offset, sm);
    @include fallback-sizes(--row-span, sm);
  }
  @media (min-width: 768px) {
    @include fallback-sizes(--area, md);
    @include fallback-sizes(--col-offset, md);
    @include fallback-sizes(--col-span, md);
    @include fallback-sizes(--row-offset, md);
    @include fallback-sizes(--row-span, md);
  }
  @media (min-width: 1024px) {
    @include fallback-sizes(--area, lg);
    @include fallback-sizes(--col-offset, lg);
    @include fallback-sizes(--col-span, lg);
    @include fallback-sizes(--row-offset, lg);
    @include fallback-sizes(--row-span, lg);
  }
}
