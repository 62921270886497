@import '../_partial.scss';

.grid {
  --cols-xs: initial;
  --cols-sm: initial;
  --cols-md: initial;
  --cols-lg: initial;
  --cols: var(--cols-xs, initial);
  --gap: inital;
}

.grid {
  display: grid;
  gap: var(--gap, initial);
  grid-template-columns: var(--cols);

  @media (min-width: 480px) {
    @include fallback-sizes(--cols, sm);
  }
  @media (min-width: 768px) {
    @include fallback-sizes(--cols, md);
  }
  @media (min-width: 1024px) {
    @include fallback-sizes(--cols, lg);
  }
}
