:root {
  --sidebar-width: 230px;
}

.sidebar {
  position: fixed;
  top: 70px;
  bottom: 0;
  background-color: var(--clr-purple-100);
  width: var(--sidebar-width);
  padding-bottom: 20px;
  color: white;
}
