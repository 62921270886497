.organizer-detail {
  .pic-container {
    display: flex;
    justify-content: center;
  }
}

// b {
//   // font-weight: 500;
//   opacity: 0.7;
//   color: orange;
// }

u {
  color: orange;
}
